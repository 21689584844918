@import 'animate.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
// @import "user-select-contain-polyfill.css";

// html,
// body,
// main,
// #__next {
//   width: 100%;
//   height: auto;
//   overflow-y: auto;
//   font-size: 100%;
// }

[data-slate-node='element']:focus-visible,
[role='textbox']:focus-visible {
  outline: none !important;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
  select {
    background: black;
  }
}

.select-wrapper-client {
  position: relative;
  select {
    background: none;
  }
}

.hide-element {
  animation-name: hide;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.select-wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid currentColor;
  pointer-events: none;
}

.select-wrapper-client::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid currentColor;
  pointer-events: none;
}

.phone {
  position: relative;
}

.phone::before {
  content: '';
  // position: absolute;
  // top: 50%;
  // right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black;
  pointer-events: none;
}

.landing-page-title {
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 124.5%;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 800px;
}

.box-shadow-white {
  box-shadow: 0px 0px 15px -1px white;
}

.box-shadow-variant-1 {
  box-shadow: 2.99714px 4.99523px 8.99142px 1.99809px rgba(0, 0, 0, 0.14);
}

.btn-variant-1 {
  @apply flex justify-center py-3 px-8 border border-white text-white text-center items-center;
  &:hover {
    @apply bg-blue-500 box-shadow-white;
  }
}

.btn-variant-2 {
  @apply flex justify-center py-3 px-8 border border-white bg-white text-center items-center text-[#2E2E35];
  &:hover {
    @apply bg-blue-500 text-white box-shadow-white;
  }
}

.btn-variant-3 {
  @apply flex justify-center py-3 px-8 bg-[#274CE0] text-center items-center text-white;
  &:hover {
    @apply box-shadow-white;
  }
}

.btn-variant-4 {
  @apply flex justify-center py-3 px-8 bg-[#ABABAB] text-center items-center text-gray-900;
  &:hover {
    @apply box-shadow-white;
  }
}

.btn-variant-5 {
  @apply flex justify-center py-3 px-28 bg-denim text-center items-center text-white;
  &:hover {
    @apply box-shadow-variant-1;
  }
}

.btn-variant-6 {
  @apply flex justify-center py-3 px-28 bg-white text-center items-center text-black-olive border border-silver;
  &:hover {
    @apply box-shadow-variant-1;
  }
}

.btn-variant-5 {
  @apply flex justify-center py-3 px-28 bg-denim text-center items-center text-white;
  &:hover {
    box-shadow: 0px 0px 20px 5px white;
  }
}

.absolutecenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.jobposting {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolutebottom {
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 100%;
  max-width: 100%;
  min-width: min-content;
}

.absolutetop {
  position: absolute;
  top: 0;
  left: 10%;
  width: 100%;
  max-width: 100%;
  min-width: min-content;
}

.table {
  border-collapse: collapse;
}

.table tr {
  transition: background-color 0.2s ease-in-out;
}

/* Apply grey background on hover */
.table tr:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.quill .ql-container {
  max-height: calc(100% - 42px);
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

//fire fox
input[type='number'] {
  -moz-appearance: textfield; /* Override Firefox default styles */
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.range-slider-thumb {
  box-shadow: 0px 0px 5.07873px rgba(0, 0, 0, 0.3),
    inset -1.01575px -2.03149px 3.04724px rgba(0, 0, 0, 0.43),
    inset 1.01575px 1.01575px 2.03149px rgba(255, 255, 255, 0.29);
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.timescroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.timescroll::-webkit-scrollbar-track {
  background: transparent;
}
.timescroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: transparent;
}

::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  height: 5px;
}

::-webkit-scrollbar-thumb {
  // background: transparent;
  border-radius: 4px; /* Rounded corners for the scrollbar handle */
  width: 20px; /* Adjust the width to reduce the length of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: transparent; /* Color of the scrollbar track */
}

.quill.ql-border {
  .ql-toolbar {
    border: none;
  }
  .ql-container {
    border: none;
  }
}
.candidate-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  color: white;
}

.book-cube-layout {
  display: grid;
  grid-template-rows: auto 1fr;
}

.modal-layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.absolute-full {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  // transform: translate(-50%, -50%);
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-right-vertical-center {
  position: absolute;
  top: 50%;
  right:3%;
  transform: translateY(-50%);
}

.absolute-right-bottom-corner {
  position: absolute;
  bottom: 20%;
  right:3%;
  transform: translateY(-50%);
}

.absolute-side-center {
  position: absolute;
  top: 70px;
  left: 40px;
}

.styled-quill {
  .ql-container {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 0.3125rem;
    border: 1px solid #3c3f59 !important;
    padding: 0px;

    overflow-y: scroll;
    max-height: initial;
    border-radius: inherit;
  }
}

.display-quill {
  .ql-editor {
    padding: 0px !important;
    overflow: initial !important;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.caret-container {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid currentColor;
  pointer-events: none;
}

.inverted-caret-container {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid currentColor;
  pointer-events: none;
}

.slide-point::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 1px;
  height: 1px;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  pointer-events: none;
}

.bg-green-gradient {
  background: linear-gradient(264deg, #0c7040 12.85%, #47426b 198.84%);
}

.bg-red-gradient {
  background: linear-gradient(264deg, #b52e31 12.85%, #acacac 198.84%);
}

/* hide original radio button*/
.custom-radio-1 {
  display: none;
}

/* Style the custom radio button container */
.custom-radio {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  position: relative;
}

/* Style the custom tick mark (hidden by default) */
.custom-radio::before {
  content: '\2713'; /* Unicode checkmark character */
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px; /* Adjust the size of the tick mark */
  font-weight: 600;
  margin-top: 1px;
  color: #ffffff;
}

input[type='radio']:checked + .custom-radio {
  background: #274ce0;
  border: none;
}

/* Style the custom radio button when checked */
input[type='radio']:checked + .custom-radio::before {
  display: block;
}
.gradient-border {
  border-left: 6px solid transparent;
  border-image: linear-gradient(to bottom, #3b82f6, #f97316, #93c5fd) 1;
  border-image-slice: 1;
}

.snap-container {
  scroll-snap-type: y mandatory;
  overflow-x: scroll;
  display: flex;
}

.snap-item {
  scroll-snap-align: center;
}

.shadow-1 {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
}
.shadow-3 {
  box-shadow: 0px 1px 15px -2px rgba(231, 231, 231);
}

.shadow-4 {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}

.top-shadow {
  box-shadow: 0px -4px 20px -10px rgba(223, 222, 222, 0.5);
}

.question-seperator {
  border-color: #9da4ad;
}

.text-gray-400 {
  color: #9799a9;
}

.background-gray-950 {
  background: #00040d;
}

.timer-gradient {
  background: linear-gradient(to left, #fd2727, #4d0000);
}

.test-modal-background {
  background-color: rgba(57, 57, 57, 0.75);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.fill-text {
  animation: fillCard 0.8s ease-in-out 0s;
}

@keyframes fillCard {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pink-radio {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #f62681;
  outline: none;

  &:focus,
  &:checked {
    outline: 2px solid #f62681;
  }

  &:checked::before {
    display: block;
  }

  /* Style the custom radio button when checked and set the background color */
  &:checked {
    background: #f62681 !important;
    border: none;
    outline-offset: 2px;
  }
}

// scaling sizes based on screen size
@media only screen and (max-width: 1800px) {
  html:not(:has(.ic-disable-resp-font)),
  body:not(:has(.ic-disable-resp-font)),
  main:not(.ic-disable-resp-font),
  #__next {
    font-size: 83%;
  }
}

@media only screen and (max-width: 1280px) {
  html:not(:has(.ic-disable-resp-font)),
  body:not(:has(.ic-disable-resp-font)),
  main:not(.ic-disable-resp-font),
  #__next {
    font-size: 70%;
  }
}

@media only screen and (max-width: 768px) {
  html:not(:has(.ic-disable-resp-font)),
  body:not(:has(.ic-disable-resp-font)),
  main:not(.ic-disable-resp-font),
  #__next {
    font-size: 85%;
  }
}

// scaling size based on screen size
// @media only screen and (max-width: 768px) {
//   html,body,main,#__next {
//     font-size: 85%;
//   }
// }

// @media only screen and (max-width: 1440px) {
//   .custom-font {
//     font-size: 85%;
//   }
// }

.shadow-custom {
  box-shadow: -8px 9px 14px -4px rgb(207, 206, 209);
}

.shadow-custom-dark {
  box-shadow: -8px 9px 14px -4px #1b213c;
}
